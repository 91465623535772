<template>
  <main>
    <div class="page-content">
      <div class="row pb-5 text-center">
        <div class="col">
          <img src="@/assets/img/startupFundraising.jpg" class="img-fluid" alt="Startup Funding" />
        </div>
        <div class="col">
          <img src="@/assets/img/filmmakerFundraising.jpg" class="img-fluid" alt="Startup Funding" />
        </div>
        <div class="col">
          <img src="@/assets/img/breweryFundraising.jpg" class="img-fluid" alt="Startup Funding" />
        </div>
      </div>
      <div class="row pb-2 text-center">
        <h3>Welcome to your raise</h3>
      </div>
      <div class="row pb-2 text-center">
        <h4>Use Round Here for your round, from $100k to $5M</h4>
      </div>
      <div class="row pb-2 text-center">
        <p>
          Sell shares, borrow money, or use convertible debt or SAFEs to turn
          your community into your investors.
        </p>
      </div>
      <div class="row pb-5 text-center">
        <div class="col-md">
          <a class="btn btn-lg btn-primary d-block" :href="signUpURL" style="margin-bottom: 5px">Sign Up</a>
        </div>
        <div class="col-md">
          <a class="btn btn-lg btn-primary d-block" :href="raiseURL" style="margin-bottom: 5px">Create Offering</a>
        </div>
        <div class="col-md">
          <router-link to="/rh/contact" class="btn btn-lg btn-primary d-block">Contact Us</router-link>
        </div>
      </div>
    </div>
  </main>
  <Footer />
</template>

<script>
import Footer from "@/components/navigation/Footer.vue";
import { ref } from "@vue/reactivity";

export default {
  components: { Footer },
  setup() {
    const signUpURL = ref(`${process.env.VUE_APP_RAISE_URL}signUp`);
    const raiseURL = ref(process.env.VUE_APP_RAISE_URL);
    return {
      signUpURL,
      raiseURL
    };
  }
};
</script>

<style></style>

